import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import "firebase/storage";

// DEV CONFIG
//import { firebaseConfig } from './config';

// PRODUCTION CONFIG
import { firebaseConfig, firebaseDevConfig } from './config';

export const firebaseStorageTaskEvent = firebase.storage.TaskEvent;
export const firebaseApp = firebase.initializeApp(firebaseDevConfig);
export const firebaseAuth = firebase.auth();
export const firebaseDb = firebase.database();
export const firebaseStorage = firebase.storage();
export const firebaseStorageRef = firebaseStorage.ref();
